
import router from './router'
import store from './store/index'
console.log(location.host)
store.commit('SET_WEBNAME', location.host)
router.beforeEach(async (to, from, next) => {
  next()
})
router.afterEach((to) => {
  store.commit('SET_NAV', to.name)
})