<template>
  <div class="nav">
    <h2>{{ title }}</h2>
    <ul>
      <li
        :class="{'active': nav === index}"
        v-for="(item, index) in navList"
        :key="'nav_' + index"
        @click="$router.push('/' + index)">
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
export default {
  name: 'GNav',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'title',
      'navList',
      'nav'
    ]),
  }
}
</script>
<style scoped>
.nav {
  width: 250px;
  height: 100%;
  background: rgba(33,33,33,1);
  text-align: left;
  padding-left: 48px;
  font-size: 19px;
  color: #fff;
  position: fixed;
  z-index: 2;
  left: 0;
}
h2 {
  padding: 50px 0 30px;
  width: 180px;
  font-weight: normal;
  font-size: 34px;
  line-height: 40px;
}
li {
  line-height: 40px;
  cursor: pointer;
}
li.active {
  color: #6b47b3;
}
</style>