export function addScriptTag(src,loaded,data){
  var _doc=document.getElementsByTagName('head')[0];
  var script = document.createElement('script');
  script.setAttribute("type","text/javascript");
  
  if(data){
    for(let i in data){
      script.setAttribute(i,data[i]);  
    }
  }
  if(script.addEventListener){
    script.addEventListener('load', function() {
      loaded();
    }, false);
  }else if(script.attachEvent){
    script.attachEvent('onreadystatechange', function() {
      var target = window.event.srcElement;
      if (target.readyState == 'loaded') {
        loaded();
      }
    });
  }
  script.src = src;
  _doc.appendChild(script);  
}
