<template>
  <div id="app">
    <g-nav />
    <router-view class="appContent" />
    <g-footer></g-footer>
  </div>
</template>
<script>
import GNav from "@/components/GNav"
import GFooter from "@/components/GFooter"
import { mapGetters } from 'vuex'
import { addScriptTag } from '@/uilts/common'
export default {
  name: 'App',
  components: {
    'g-nav': GNav,
    'g-footer': GFooter
  },
  computed: {
    ...mapGetters([
      'webName'
    ]),
  },
  created() {
    addScriptTag('/js/'+ this.webName +'.js', ()=>{
      console.log(webData)
      this.$store.commit('SET_TITLE', webData.title)
      this.$store.commit('SET_NAV_LIST', webData.navList)
      this.$store.commit('SET_COPYRIGHT', webData.copyright)
    })
  }
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
}
ul, li {
  list-style: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.appContent {
  margin-left: 250px;
}
</style>
