
const common = {
  state: {
    copyright: '',
    title: '',
    navList: [],
    nav: 'home',
    webName: 'www.dracoomaster.live'
  },
  actions: {
  },
  mutations: {
    SET_TITLE: (state, title) => {
      state.title = title
    },
    SET_COPYRIGHT: (state, copyright) => {
      state.copyright = copyright
    },
    SET_NAV_LIST: (state, navList) => {
      state.navList = navList
    },
    SET_NAV: (state, nav) => {
      state.nav = nav
    },
    SET_WEBNAME: (state, webName) => {
      state.webName = webName
    }
    
  }
}
export default common
